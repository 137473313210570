import PropTypes from "prop-types";
import React from "react";
import { InputLabel } from "../Typography";
import { Input, ValidationError } from "./Input";

const InputWithLabelAndError = ({
  id,
  type,
  label,
  value,
  placeholder,
  required,
  disabled,
  validationError,
  handleChange,
  maxLength  
}) => {
  return (
    <>
      {label && (
        <InputLabel isRequired={required} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <Input
        type={type}
        id={id}
        onChange={({ target }) => handleChange(target.value)}
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
      />
      <ValidationError>{validationError && validationError}</ValidationError>
    </>
  );
};

export const TextInputWithLabelAndError = (props) => {
  return <InputWithLabelAndError type={"text"} {...props} />;
};

export const PhoneInputWithLabelAndError = (props) => {
  return <InputWithLabelAndError type={"tel"} {...props} />;
};

export const EmailInputWithLabelAndError = (props) => {
  return <InputWithLabelAndError type={"email"} {...props} />;
};

const inputPropTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleChange: PropTypes.func,
};

TextInputWithLabelAndError.propTypes = inputPropTypes;
PhoneInputWithLabelAndError.propTypes = inputPropTypes;
EmailInputWithLabelAndError.propTypes = inputPropTypes;

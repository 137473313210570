import Cookies from "js-cookie";
import PropTypes from "prop-types";
import psl from "psl";
// eslint-disable-next-line no-unused-vars
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useGet } from "../utils";

const COOKIE = { CARE_CENTER_COOKIE: "CareCenterCookie" };
const CareCenterContext = createContext({});
const parsed = psl.parse(window.location.hostname);
const domain = parsed.domain ? parsed.domain : parsed.input;

const getCookieName = (siteName) => {
  return `${siteName}-${COOKIE.CARE_CENTER_COOKIE}`;
}

const getCookie = (siteName) => {
  const cookieName = getCookieName(siteName);
  const cookie = Cookies.get(cookieName);
  const decodedCookie = cookie && window.atob(cookie);
  if (!decodedCookie) return null;
  return JSON.parse(decodedCookie);
};

const setCookie = (userId, careCenterId, siteName) => {
  const cookieObject = {
    userId,
    careCenterId,
  };
  const serializedCookieObject = JSON.stringify(cookieObject);
  const encodedCookieObject = window.btoa(serializedCookieObject);
  const cookieName = getCookieName(siteName);
  Cookies.set(cookieName, encodedCookieObject, {
    domain: domain,
    expires: 30,
  });
};

const getCareCenterIdsFromUser = (user) => {
  if (!user || !user.profile || !user.profile.carecenter) {
    return [];
  }
  //  If user belongs to multiple carecenters this is a array
  if (Array.isArray(user.profile.carecenter)) {
    return user.profile.carecenter.map((id) => parseInt(id));
  }
  //  Only one carecenter so type is string
  return [parseInt(user.profile.carecenter)];
};

const getCareCenterIdFromCookie = (user, siteName) => {
  const cookie = getCookie(siteName);
  if (!cookie) return null;

  if (cookie.userId === user.profile.sub) {
    const careCenterId = parseInt(cookie.careCenterId);
    if (getCareCenterIdsFromUser(user).find((id) => id === careCenterId)) {
      return careCenterId;
    }
  }
  Cookies.remove(getCookieName(siteName), { domain: domain });
  return null;
};

const CareCenterContextProvider = ({ children, apiUrl, siteName, oidcUser}) => {
  const [currentCareCenterId, setCurrentCareCenterId] = useState(
    getCareCenterIdFromCookie(oidcUser, siteName)
  );

    useEffect(() => {
        if (oidcUser && currentCareCenterId) {
            setCookie(oidcUser.profile.sub, currentCareCenterId, siteName);
        }
    }, [oidcUser, currentCareCenterId, siteName]);

  const {
    response: careCenters = [],
    isLoading: isLoadingCareCenters,
    doGet: reFetchCareCenters,
  } = useGet(apiUrl, oidcUser && oidcUser.access_token);

  const userCareCenters = useMemo(() => {
    const userCareCenterIds = getCareCenterIdsFromUser(oidcUser);
    return careCenters.filter((careCenter) =>
      userCareCenterIds.includes(careCenter.id)
    );
  }, [oidcUser, careCenters]);

  const currentCareCenter = useMemo(
    () =>
      userCareCenters.find(
        (careCenter) => careCenter.id === currentCareCenterId
      ),
    [userCareCenters, currentCareCenterId]
  );

  const setCareCenter = useCallback(
    (careCenterId) => {
      const userHasCareCenter = userCareCenters.find(
        (userCareCenter) => userCareCenter.id === careCenterId
      );
      if (!userHasCareCenter) {
        return;
      }
      setCurrentCareCenterId(careCenterId);
    },
    [userCareCenters]
  );

  const removeCareCenter = useCallback(() => {
    Cookies.remove(getCookieName(siteName), { domain: domain });
    setCurrentCareCenterId(null);
  }, []);

  return (
    <CareCenterContext.Provider
      value={{
        careCenters: userCareCenters,
        currentCareCenter: currentCareCenter,
        currentCareCenterId: currentCareCenterId,
        isLoadingCareCenters: isLoadingCareCenters,
        setCurrentCareCenter: setCareCenter,
        removeCurrentCareCenter: removeCareCenter,
        reFetchCareCenters: reFetchCareCenters,
      }}
    >
      {children}
    </CareCenterContext.Provider>
  );
};

export { CareCenterContext, CareCenterContextProvider };

CareCenterContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  apiUrl: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  oidcUser: PropTypes.shape({
  access_token: PropTypes.string.isRequired,
  profile: PropTypes.shape({
      sub: PropTypes.string.isRequired
    })
  }).isRequired
};

import { useCallback, useEffect, useMemo, useState } from "react";

export const HTTP_METHODS = {
  DELETE: "DELETE",
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
};

export const MIME_TYPES = {
  BINARY: "application/octet-stream",
  JSON: "application/json",
};

const DEFAULT_FETCH_OPTIONS = {
  method: HTTP_METHODS.GET,
  headers: {
    Accept: MIME_TYPES.JSON,
  },
};

const jsonResultResolver = (response) => response.json();
export const emptyResultResolver = (response) => Promise.resolve(undefined);
const blobResultResolver = (response) => response.blob();

/**
 * React hook for making request with fetch.
 * @param {boolean} isLoadingState - Used to immediately set to isLoading state. Defaults to false.
 * @returns {{isLoading: boolean, response: *, success: boolean, doFetch: (function(*=, *=, *=): {}), error: {string}}}
 */
export const useFetch = (isLoadingState = false) => {
  const [isLoading, setIsLoading] = useState(isLoadingState);
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  /**
   *
   * @param {string} url - The URL to fetch from.
   * @param {object} options - The fetch options
   * @param responseResolver - A resolver that can resolve the expected response
   * @returns {Promise<{}>} - A promise that is resolved with the response
   */
  const doFetch = useCallback(
    async (
      url,
      options = DEFAULT_FETCH_OPTIONS,
      responseResolver = jsonResultResolver
    ) => {
      let responseData = {};
      setIsLoading(true);
      setSuccess(null);
      setError(null);
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`Server responded with status ${response.status}`);
        } else {
          responseData = await responseResolver(response);
          setResponse(responseData);
        }
        setSuccess(true);
        setError(undefined);
      } catch (e) {
        console.log(e);
        setError(e);
        setSuccess(false);
      }
      setIsLoading(false);
      return responseData;
    },
    []
  );

  return {
    doFetch,
    response,
    success,
    isLoading,
    error,
  };
};

/***
 * React hook for making a GET request with fetch.
 * @param {?string} url - The URL to fetch from. Set to null if you want to pass the URL in the doGet function instead.
 * @param {string} authorizationToken - The authorization token
 * @param {boolean} fetchImmediately - Defaults to true. If set to false, doGet has to be manually called.
 * @param {object} headers - Custom header parameter(s). Default to {}.
 * @param overrideResponseResolver {function(Promise<{object}>): Promise<{object}>}- - response resolver to use instead of default json resolver
 * @returns {{isLoading: boolean, response: *, success: boolean, error: {string}, doGet: (function(*=): {})}}
 */
export const useGet = (
  url,
  authorizationToken,
  fetchImmediately = true,
  headers = {},
  overrideResponseResolver = undefined
) => {
  const { doFetch, isLoading, response, error, success } =
    useFetch(fetchImmediately);

  const options = useMemo(
    () => ({
      method: HTTP_METHODS.GET,
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        "Content-Type": MIME_TYPES.JSON,
        Accept: MIME_TYPES.JSON,
        ...headers,
      },
    }),
    [authorizationToken, ...Object.keys(headers), ...Object.values(headers)]
  );

  /**
   * Makes GET request with fetch.
   * @param {string} urlOverride - Overrides to URL passed to useGet. Use this if you want to make request to different URLs.
   * @param {?object} headers - If you want to add to header
   * @returns {Promise<{object}>} - A promise that is resolved with the JSON response.
   */
  const doGet = useCallback(
    async (urlOverride = url, customHeaderOverride = {}) => {
      options.headers = { ...options.headers, ...customHeaderOverride };
      doFetch(
        urlOverride,
        options,
        overrideResponseResolver ?? jsonResultResolver
      );
    },
    [options, url, doFetch, overrideResponseResolver]
  );

  useEffect(() => {
    const abortController = new AbortController();
    options.signal = abortController.signal;
    fetchImmediately && doGet();
    return () => {
      abortController.abort();
    };
  }, [fetchImmediately, doGet]);

  return {
    doGet,
    isLoading,
    response,
    error,
    success,
  };
};

/**
 * React hook for making a GET request with fetch. Used when requesting a blob.
 * @param {?string} url - The URL to fetch from. Set to null if you want to pass the URL in the doGetBlob function instead.
 * @param {string} authorizationToken - The authorization token
 * @returns {{isLoading: boolean, response: *, success: boolean, doGetBlob: (function(*=): {}), error: {string}}}
 */
export const useGetBlob = (url, authorizationToken) => {
  const { doFetch, isLoading, response, error, success } = useFetch();

  const options = useMemo(
    () => ({
      method: HTTP_METHODS.GET,
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        Accept: MIME_TYPES.BINARY,
      },
    }),
    [authorizationToken]
  );

  /**
   * Makes GET request with fetch. Used when requesting a blob.
   * @param {string} urlOverride - Overrides to URL passed to useGet. Use this if you want to make request to different URLs.
   * @returns {Promise<{blob}>} - A promise that is resolved with the blob response.
   */
  const doGetBlob = useCallback(
    async (urlOverride = url) =>
      doFetch(urlOverride, options, blobResultResolver),
    [url, options, doFetch]
  );

  return {
    doGetBlob,
    isLoading,
    response,
    error,
    success,
  };
};

/**
 * React hook for making a POST request with fetch.
 * @param {?string} url - The URL to post to. Set to null if you want to pass the URL in the doPost function instead.
 * @param {string} authorizationToken - The authorization token
 * @param overrideResponseResolver {function(Promise<{object}>): Promise<{object}>}- - response resolver to use instead of default json resolver
 * @returns {{isLoading: boolean, response: *, success: boolean, error: {string}, doPost: (function(*=, *=): {})}}
 */
export const usePost = (
  url,
  authorizationToken,
  overrideResponseResolver = undefined
) => {
  const { doFetch, isLoading, response, error, success } = useFetch();

  const options = useMemo(
    () => ({
      method: HTTP_METHODS.POST,
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        Accept: MIME_TYPES.JSON,
        "Content-Type": MIME_TYPES.JSON,
      },
    }),
    [authorizationToken]
  );

  /**
   * Makes POST request with fetch.
   * @param {object} data - The data object to send
   * @param {string} urlOverride - Overrides to URL passed to usePost. Use this if you want to post to different URLs.
   * @returns {Promise<{object}>} - A promise that is resolved with the JSON response.
   */
  const doPost = useCallback(
    async (data, urlOverride = url) => {
      options.body = JSON.stringify(data);
      return doFetch(
        urlOverride,
        options,
        overrideResponseResolver ?? jsonResultResolver
      );
    },
    [url, options, doFetch, overrideResponseResolver]
  );

  return {
    doPost,
    isLoading,
    response,
    error,
    success,
  };
};

/**
 * React hook for making a POST request with fetch. Used when sending form data.
 * @param {?string} url - The URL to post to. Set to null if you want to pass the URL in the doPostForm function instead.
 * @param overrideResponseResolver {function(Promise<{object}>): Promise<{object}>}- - response resolver to use instead of default json resolver
 * @param {string} authorizationToken - The authorization token
 * @returns {{doPostForm: (function(*, *=): {}), isLoading: boolean, response: *, success: boolean, error: {string}}}
 */
export const usePostForm = (
  url,
  authorizationToken,
  overrideResponseResolver = undefined
) => {
  const { doFetch, isLoading, response, error, success } = useFetch();

  const options = useMemo(
    () => ({
      method: HTTP_METHODS.POST,
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        Accept: MIME_TYPES.JSON,
      },
    }),
    [authorizationToken]
  );

  /**
   * Makes POST request with fetch. Used when sending form data.
   * @param {?object} data - The data object to send
   * @param {string} urlOverride - Overrides to URL passed to usePostForm. Use this if you want to post to different URLs.
   * @returns {Promise<{object}>} - A promise that is resolved with the JSON response.
   */
  const doPostForm = useCallback(
    async (data, urlOverride = url) => {
      options.body = data;
      return doFetch(
        urlOverride,
        options,
        overrideResponseResolver ?? jsonResultResolver
      );
    },
    [url, options, doFetch, overrideResponseResolver]
  );

  return {
    doPostForm,
    isLoading,
    response,
    error,
    success,
  };
};

/**
 * React hook for making a PUT request with fetch.
 * @param {?string} url - The URL to make PUT request to. Set to null if you want to pass the URL in the doPut function instead.
 * @param {string} authorizationToken - The authorization token
 * @param overrideResponseResolver {function(Promise<{object}>): Promise<{object}>}- - response resolver to use instead of default json resolver
 * @returns {{doPut: (function(*=, *=): {}), isLoading: boolean, response: *, success: boolean, error: {string}}}
 */
export const usePut = (
  url,
  authorizationToken,
  overrideResponseResolver = undefined
) => {
  const { doFetch, isLoading, response, error, success } = useFetch(false);

  const options = useMemo(
    () => ({
      method: HTTP_METHODS.PUT,
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        Accept: MIME_TYPES.JSON,
        "Content-Type": MIME_TYPES.JSON,
      },
    }),
    [authorizationToken]
  );

  /**
   * Makes PUT request with fetch.
   * @param {object} data - The data object to send
   * @param {string} urlOverride - Overrides to URL passed to usePut. Use this if you want to make requests to different URLs.
   * @returns {Promise<{object}>} - A promise that is resolved with the JSON response.
   */
  const doPut = useCallback(
    async (data, urlOverride = url) => {
      options.body = JSON.stringify(data);
      return doFetch(
        urlOverride,
        options,
        overrideResponseResolver ?? jsonResultResolver
      );
    },
    [url, options, doFetch, overrideResponseResolver]
  );

  return {
    doPut,
    isLoading,
    response,
    error,
    success,
  };
};

/**
 * React hook for making a PATCH request with fetch.
 * @param {?string} url - The URL to make PATCH request to. Set to null if you want to pass the URL in the doPut function instead.
 * @param {string} authorizationToken - The authorization token
 * @param overrideResponseResolver {function(Promise<{object}>): Promise<{object}>}- - response resolver to use instead of default json resolver
 * @returns {{doPatch: (function(*=, *=): {}), isLoading: boolean, response: *, success: boolean, error: {string}}}
 */
export const usePatch = (
  url,
  authorizationToken,
  overrideResponseResolver = undefined
) => {
  const { doFetch, isLoading, response, error, success } = useFetch(false);

  const options = useMemo(
    () => ({
      method: HTTP_METHODS.PATCH,
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        Accept: MIME_TYPES.JSON,
        "Content-Type": MIME_TYPES.JSON,
      },
    }),
    [authorizationToken]
  );

  /**
   * Makes PUT request with fetch.
   * @param {object} data - The data object to send
   * @param {string} urlOverride - Overrides to URL passed to usePut. Use this if you want to make requests to different URLs.
   * @returns {Promise<{object}>} - A promise that is resolved with the JSON response.
   */
  const doPatch = useCallback(
    async (data, urlOverride = url) => {
      options.body = JSON.stringify(data);
      return doFetch(
        urlOverride,
        options,
        overrideResponseResolver ?? jsonResultResolver
      );
    },
    [url, options, doFetch, overrideResponseResolver]
  );

  return {
    doPatch,
    isLoading,
    response,
    error,
    success,
  };
};

/**
 * React hook for making a DELETE request with fetch.
 * @param {?string} url - The URL to make DELETE request to. Set to null if you want to pass the URL in the doDelete function instead.
 * @param {string} authorizationToken - The authorization token
 * @returns {{isLoading: boolean, doDelete: (function(*=): {}), response: *, success: boolean, error: {string}}}
 */
export const useDelete = (url, authorizationToken) => {
  const { doFetch, isLoading, response, error, success } = useFetch(false);

  const options = useMemo(
    () => ({
      method: HTTP_METHODS.DELETE,
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        Accept: MIME_TYPES.JSON,
      },
    }),
    [authorizationToken]
  );

  /**
   * Makes PUT request with fetch.
   * @param {string} urlOverride - Overrides to URL passed to useDelete. Use this if you want to make requests to different URLs.
   * @returns {Promise<{object}>} - A promise that is resolved with the JSON response.
   */
  const doDelete = useCallback(
    async (urlOverride = url) =>
      doFetch(urlOverride, options, jsonResultResolver),
    [url, options, doFetch]
  );

  return {
    doDelete,
    isLoading,
    response,
    error,
    success,
  };
};

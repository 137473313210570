import PropTypes from "prop-types";

/**
 * Validates file count, size, type and checks for duplicate names
 * @param {File[]} filesToAdd - array of files to add
 * @param {File[]} existingFiles - already added files used for validation
 * @param {Object} config - optional configuration object
 * @param {function(File[])} config.filesCallback - callback with array of all added files
 * @param {function(string[])} config.errorsCallback - callback with file validation errors (array)
 * @param {int} config.maxMBFileSize - maximum file size in MB, if null 3
 * @param {int} config.maxFileCount - maximum file count, if null 5
 * @param {string[]} config.acceptedFileTypes - array of accepted file types, if null png, jpeg, pdf
 * @returns {[files:File[], errors: string[]]} output - an array consisting of the validated files and the validation errors
 */
const fileUploadValidation = (filesToAdd, existingFiles,
                              {
                                  filesCallback = () => {
                                  },
                                  errorsCallback = () => {
                                  },
                                  maxMBFileSize = 3,
                                  maxFileCount = 5,
                                  maxFileNameLength = 100,
                                  acceptedFileTypes = ["image/jpeg", "image/png", "application/pdf"]
                              } = {}) => {

    const maxByteFileSize = maxMBFileSize * 1024 * 1024;

    const getFileSubType = fileType => fileType.split("/").pop();
    const getAcceptedFileTypesString = () => acceptedFileTypes.map(fileType => getFileSubType(fileType)).join(", ")
    const filesArray = Array.from(filesToAdd);
    const fileValidationErrors = [];

    if (filesArray.length + existingFiles.length > maxFileCount) {
        fileValidationErrors.push(`Maximalt antal filer är ${maxFileCount}.`)
    } else {
        filesArray.forEach(file => {
            
            const filenameShort = file.name.substring(0, file.name.lastIndexOf('.')).length || file.name.length;
            
            if (!acceptedFileTypes.includes(file.type)) {
                fileValidationErrors.push(`Otillåtet filformat. Tillåtna filformat: ${getAcceptedFileTypesString()}.`);
            } else if (file.size > maxByteFileSize) {
                fileValidationErrors.push(`${file.name} är för stor.`);
            } else if (existingFiles.some(f => f.name === file.name)) {
                fileValidationErrors.push(`En fil med namnet ${file.name} finns redan.`);
            } else if (filenameShort > maxFileNameLength) {
                fileValidationErrors.push(`Filnamnet får vara max ${maxFileNameLength} tecken.`);
            } else {
                existingFiles.push(file)
            }
        });
    }

    errorsCallback(fileValidationErrors);
    filesCallback(existingFiles);

    return [existingFiles, fileValidationErrors];
}

fileUploadValidation.propTypes = {
    filesToAdd: PropTypes.arrayOf(PropTypes.instanceOf(File)),
    existingFiles: PropTypes.arrayOf(PropTypes.instanceOf(File)),
    config: PropTypes.shape({
        filesCallback: PropTypes.func,
        errorsCallback: PropTypes.func,
        maxFileSizeMb: PropTypes.number,
        maxFileCount: PropTypes.number,
        acceptedFileTypes: PropTypes.arrayOf(PropTypes.string)
    })
};

export default fileUploadValidation;

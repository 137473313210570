import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import styled from "styled-components";

const NavbarLogo = styled.img`
  height: ${({ theme }) => theme.navbar.logo.height};
  margin-top: ${({ theme }) => theme.navbar.logo.marginTop};
  margin-bottom: ${({ theme }) => theme.navbar.logo.marginBottom};
  cursor: pointer;
`;

const StyledNavbar = styled(Navbar)`
  color: ${({ theme }) => theme.navbar.color};
  background-color: ${({ theme }) => theme.navbar.backgroundColor};
  border-bottom-color: ${({ theme }) => theme.navbar.border.color};
  border-bottom-width: ${({ theme }) => theme.navbar.border.width};
  border-bottom-style: solid;
`;

const StyledNavbarToggler = styled(Navbar.Toggle)`
  color: ${({ theme }) => theme.navbar.color};
  &:active,
  :focus {
    border: none;
    outline: none;
    color: ${({ theme }) => theme.navbar.color};
  }
`;

const StyledWrap = styled.div`
  & .nav-link {
    color: ${({ theme }) => theme.navbar.color} !important;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.navbar.link.hover.color} !important;
      background-color: ${({ theme }) =>
        theme.navbar.link.hover.backgroundColor};
    }
  }

  & .navbar-toggler {
    border-color: transparent;
    color: ${({ theme }) => theme.navbar.color};
  }

  & .dropdown-menu {
    border-radius: 0px;
    cursor: pointer;
    max-height: 75vh;
    overflow-y: auto;
  }

  & .dropdown-item {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.navbar.link.hover.color} !important;
      background-color: ${({ theme }) =>
        theme.navbar.link.hover.backgroundColor};
    }
  }
`;

const NavBar = ({ logo, logohandler, items = [], dropdown }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const handleClickLink = (handler) => {
    setCollapsed(true);
    handler();
  };

  const handleMenuClick = (id, handler) => {
    handler(id);
  };

  if (dropdown) {
    dropdown.dropdownitems.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <StyledWrap>
      <StyledNavbar expand="lg" fixed="top">
        <Navbar.Brand onClick={(e) => logohandler && logohandler(e)}>
          {logo && <NavbarLogo alt="Logo" src={logo} />}
        </Navbar.Brand>
        <StyledNavbarToggler onClick={toggleNavbar}>
          <FontAwesomeIcon icon={collapsed ? faBars : faTimes} />
        </StyledNavbarToggler>
        <Navbar.Collapse>
          <Nav navbar className="ml-auto">
            {dropdown && (
              <NavDropdown title={dropdown.selected} alignRight={true}>
                {dropdown.dropdownitems.map(({ label, id, handler }) => (
                  <NavDropdown.Item
                    key={id}
                    onClick={() => handler && handleMenuClick(id, handler)}
                  >
                    {label}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            )}
            {items.map(({ label, url, handler }) => (
              <Nav.Item key={label}>
                <Nav.Link
                  onClick={() => handler && handleClickLink(handler)}
                  href={url}
                >
                  {label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
    </StyledWrap>
  );
};

NavBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      url: PropTypes.string,
      handler: PropTypes.func,
    })
  ),
  dropdown: PropTypes.shape({
    selected: PropTypes.string,
    dropdownitems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        handler: PropTypes.func,
      })
    ),
  }),
  logo: PropTypes.node,
  logohandler: PropTypes.func,
};

export default NavBar;

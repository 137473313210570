import { useContext } from "react";
import { CareCenterContext } from "./CareCenterContext";

export const CareCenterRequirement = ({ children, portalUrl }) => {
  const { currentCareCenterId } = useContext(CareCenterContext);
  if (!currentCareCenterId) {
    window.location.href = portalUrl;
  }
  return children;
};
